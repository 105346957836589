<template>
  <div class="container-fluid">
    <div class="row py-3">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th class="text-right">#</th>
                <th>{{ $t('team') }}</th>
                <th>DT/CODT</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(team, index) in teams" :key="index">
                <td class="text-right">{{ index + 1 }}</td>
                <td>
                  <router-link :to="{ name: 'TeamHome', params: { lang:lang, console: console, teamSlug: team.slug } }">
                    <img :src="team.image" :alt="team.name" class="img-icon mr-2">
                    {{ team.name }}
                  </router-link>
                </td>
                <td>
                  <router-link v-if="team.dt" :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: team.dt.slug } }">DT {{ team.dt.nick }}</router-link>
                  <router-link v-if="team.codt && !team.dt" :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: team.codt.slug } }">CODT {{ team.codt.nick }}</router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      teams: []
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console'
    ])
  },
  created() {
    this.$store.dispatch('SET_MENU_PRIMARY_TOURNAMENT', 'home')
    this.fetchData()
  },
  methods: {
    fetchData() {
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const slug = this.$route.params.tournamentSlug
      const path = `${lang}/console/${console}/tournament/${slug}/my-teams`
      this.$axios.get(path).then((response) => {
        this.teams = response.data.data
      })
    }
  }
}
</script>
